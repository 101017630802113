import { memo } from "react";
import * as Styled from "./styles";
import type { BoxAccordionProps } from "./types";

const BoxAccordion = ({
  children,
  disabled,
  expanded,
  ariaControls,
  accordionId,
  summary,
  className,
  onChange,
  leftIcon,
  summaryBackgroundColor,
}: BoxAccordionProps): JSX.Element => (
  <Styled.BoxAccordion
    className={className}
    disabled={disabled}
    defaultExpanded={expanded}
    onChange={onChange}
    data-testid="box-accordion"
  >
    <Styled.BoxAccordionSummary
      aria-controls={ariaControls}
      id={accordionId}
      $backgroundColor={summaryBackgroundColor}
      data-testid="box-accordion-summary"
    >
      {leftIcon && (
        <Styled.LeftIconWrapper data-testid="left-icon">
          {leftIcon}
        </Styled.LeftIconWrapper>
      )}
      <Styled.SummaryContent $size="md" $weight="bold">
        {summary}
      </Styled.SummaryContent>
      {!disabled && <Styled.ArrowIcon data-testid="arrow-icon" />}
    </Styled.BoxAccordionSummary>
    <Styled.BoxAccordionDetails data-testid="box-accordion-details">
      {children}
    </Styled.BoxAccordionDetails>
  </Styled.BoxAccordion>
);

export default memo(BoxAccordion);
