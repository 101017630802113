import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";
import { Body1 } from "@/components/Typography";
import DefaultCloseIcon from "@mui/icons-material/Close";
import { getStyledIcon } from "@/utils/dynamicIcons";

export const DrawerHeader = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${defaultTheme.renewTheme.colors.arctic25};
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
`;

export const DetailSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RenewalActivityItem = styled(Body1)`
  color: ${defaultTheme.renewTheme.colors.grey600};
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    height: 12px;
    width: 12px;
  }
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  height: 12px;
  width: 12px;
  color: ${defaultTheme.renewTheme.colors.night400};
`;

export const PDFDownload = styled(Body1)`
  color: ${defaultTheme.renewTheme.colors.night400};
  line-height: 21px;
  margin-right: 4px;
`;

export const DownloadIcon = getStyledIcon("FileDownloadOutlinedIcon", {
  size: "12px",
  background: true,
  border: false,
  backgroundColor: defaultTheme.renewTheme.colors.night400,
  color: defaultTheme.renewTheme.colors.white,
});
export const ViewIcon = getStyledIcon("VisibilityOutlinedIcon", {
  size: "12px",
  background: true,
  border: false,
  backgroundColor: defaultTheme.renewTheme.colors.night400,
  color: defaultTheme.renewTheme.colors.white,
});
