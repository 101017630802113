import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import ArrowDown from "@mui/icons-material/ArrowDropDown";
import UtilizeSpacer from "@/styles/theme-styles";
import { StyledBoxAccordionProps } from "./types";
import { Body1 } from "../Typography";

// Common theme values
const { colors } = defaultTheme.renewTheme;

// Common styles
const boxShadowStyle = `
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
`;

const flexCenter = `
  display: flex;
  align-items: center;
`;

export const BoxAccordion = styled(MuiAccordion)`
  ${boxShadowStyle}
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  background-color: ${colors.white};
  overflow: hidden;
  &.MuiAccordion-root {
    margin-top: 0;
  }
  &:before {
    display: none;
  }
`;

export const BoxAccordionSummary = styled(
  MuiAccordionSummary
)<StyledBoxAccordionProps>`
  background-color: ${(props) =>
    props.$backgroundColor ? colors[props.$backgroundColor] : colors.grey100};
  min-height: 56px;
  .MuiAccordionSummary-content {
    ${flexCenter}
    ${UtilizeSpacer({
      options: [
        { size: "3xs", direction: [0, 2] },
        { size: "2xs", direction: [1] },
      ],
      type: "margin",
    })}
  }

  &.Mui-expanded {
    min-height: 56px;
  }
`;

export const BoxAccordionDetails = styled(MuiAccordionDetails)`
  ${UtilizeSpacer({
    options: [{ size: "2xs", direction: [0, 1, 2, 3] }],
    type: "margin",
  })}
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LeftIconWrapper = styled.div`
  ${flexCenter}
  ${UtilizeSpacer({
    specificOption: { size: "3xs", direction: "right" },
    type: "margin",
  })}
  
  svg {
    width: 12px;
  }
`;

export const SummaryContent = styled(Body1)`
  flex: 1;
  ${flexCenter}
`;

export const ArrowIcon = styled(ArrowDown)`
  color: ${colors.black};
  height: 16px;
  width: 16px;
  ${UtilizeSpacer({
    specificOption: { size: "xs", direction: "left" },
    type: "margin",
  })}
  transition: transform 150ms ease-in;

  .Mui-expanded & {
    transform: rotate(180deg);
  }
`;
