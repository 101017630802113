import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { Body1, StyledLink } from "@/components/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {
  RenewalOfferWorkflowStatus,
  DocumentType,
  Disposition,
} from "@/graphql/generated";
import { CheckCircleOutline, Checklist } from "@mui/icons-material";
import StatusPill from "@/components/StatusPill";

import useDownloadUrl from "@/graphql/hooks/downloadUrl/useDownloadUrl";
import { withoutNulls } from "@/utils/withoutNulls";
import showSnackbar from "@/utils/showSnackbar";
import BoxAccordion from "@/components/BoxAccordion";
import SplitColumnDataPanel from "@/components/SplitColumnDataPanel";
import { RowType } from "../StatusTables/types";
import * as Styled from "./styles";
import useConnect from "./connect";
import RenewalTimeline from "./RenewalTimeline";

interface DetailDrawerProps {
  renewalOffer?: RowType;
}

const DetailDrawer: React.FC<DetailDrawerProps> = ({ renewalOffer }) => {
  const { downloadUrl } = useDownloadUrl({});

  const downloadLink = React.useCallback(
    async (id?: string, shouldDownload = false) => {
      const url = downloadUrl({
        objectId: id,
        objectType: DocumentType.RenewalOffer,
        disposition: shouldDownload ? Disposition.Attachment : undefined,
      });
      const downloadData = withoutNulls((await url)?.data?.downloadablePdfUrl);
      if (
        downloadData?.__typename === "DownloadablePdfUrl" &&
        downloadData?.url
      ) {
        if (shouldDownload) {
          window.open(downloadData?.url);
        } else {
          window.open(downloadData?.url, "_blank");
        }
      }
    },
    [downloadUrl]
  );

  const onAction = (id?: string, shouldDownload = false) => {
    downloadLink(id, shouldDownload).catch(() => {
      showSnackbar(
        "There has been an error with your PDF. Please try again later.",
        "error"
      );
    });
  };

  const { open, activityItems, unitName, onClose, unit, status } =
    useConnect(renewalOffer);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "740px",
            boxSizing: "border-box",
            borderRadius: "8px 0 0 8px",
          },
        }}
      >
        <Styled.DrawerHeader>
          <Styled.HeaderTextContainer>
            <Body1 $size="lg" $weight="bold">
              {unit?.communityName}
            </Body1>
            <Body1 $size="sm" $weight="regular" $color="grey600">
              {unitName}
              <StyledLink
                $size="sm"
                $weight="medium"
                $underlined
                $inline
                $color="night400"
                style={{ marginLeft: "8px" }}
                href={`/units/${unit?.id || ""}`}
              >
                View unit profile
              </StyledLink>
            </Body1>
            <StatusPill
              color={status.color}
              bgColor={status.bgColor}
              label={status.title}
              icon={
                renewalOffer?.workflowStatus ===
                  RenewalOfferWorkflowStatus.Completed && <CheckCircleOutline />
              }
            />
          </Styled.HeaderTextContainer>
          {renewalOffer?.pdfUrl ? (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Styled.PDFDownload $size="xs" $weight="medium">
                Renewal offer PDF
              </Styled.PDFDownload>
              <button
                type="button"
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                }}
                onClick={() => onAction(renewalOffer?.id, true)}
              >
                {Styled.DownloadIcon}
              </button>
              <button
                type="button"
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                }}
                onClick={() => onAction(renewalOffer?.id, false)}
              >
                {Styled.ViewIcon}
              </button>
              <IconButton
                onClick={onClose}
                edge="end"
                aria-label="close"
                sx={{ top: "-10px" }}
              >
                <Styled.CloseIcon />
              </IconButton>
            </div>
          ) : (
            <Styled.PDFDownload $size="xs" $weight="medium">
              No Renewal Offer PDF Available
              <IconButton
                onClick={onClose}
                edge="end"
                aria-label="close"
                sx={{ top: "-10px" }}
              >
                <Styled.CloseIcon />
              </IconButton>
            </Styled.PDFDownload>
          )}
        </Styled.DrawerHeader>

        <Styled.DrawerContent>
          <RenewalTimeline status={renewalOffer?.workflowStatus} />
          <BoxAccordion
            expanded
            summary="Renewal Activity"
            leftIcon={<Checklist />}
          >
            <SplitColumnDataPanel sections={activityItems} />
          </BoxAccordion>
        </Styled.DrawerContent>
      </Drawer>
    </>
  );
};

export default DetailDrawer;
