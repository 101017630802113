/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React from "react";
import { useRouter } from "next/router";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortCellParams,
} from "@mui/x-data-grid";
import defaultTheme from "@/styles/themes/default";

import { formatDate } from "@/utils/formatDate";
import { RenewalOfferDecisionType } from "@/graphql/generated";
import { Body1 } from "@/components/Typography";
import ToolTip from "@/components/Tooltip";
import { determineNeedSomethingElseCopy } from "@/utils/determineNeedSomethingElseCopy";
import addAlpha from "@/utils/addAlpha";
import StatusPill from "@/components/StatusPill";
import {
  AttachEmailOutlined,
  InfoOutlined,
  MarkEmailReadOutlined,
} from "@mui/icons-material";
import * as Styled from "./styles";
import { RowType } from "./types";

type DeterminedStatusRenewalOffer = RowType["determinedStatus"];
type DecisionRenewalOffer = RowType["decision"];

const ActionItemPill = ({
  href,
  tooltipContent,
  copy,
}: {
  href?: string;
  tooltipContent: string;
  copy: string;
}) => (
  <Styled.StatusPillWrapper href={href}>
    <StatusPill
      label={copy}
      icon={<InfoOutlined />}
      color="grey600"
      bgColor="warning400"
      tooltipText={tooltipContent}
    />
  </Styled.StatusPillWrapper>
);

const getDecisionInfo = (value: DecisionRenewalOffer) => {
  const input: { decision: string; info?: string } = {
    decision: "No decision made",
  };

  if (
    value?.type === RenewalOfferDecisionType.Interested ||
    value?.type === RenewalOfferDecisionType.InterestedLate
  ) {
    input.decision = "Accepted";
    input.info = `${
      value.interestedTerm?.lengthInMonths
        ? `${value.interestedTerm?.lengthInMonths}/mo lease - `
        : ""
    }${
      value.interestedTerm?.baseRent
        ? `$${value.interestedTerm?.baseRent}/mo `
        : ""
    }${
      value.decidedAt
        ? `- Submitted on ${formatDate(new Date(value.decidedAt))}`
        : ""
    }`;
  } else if (value?.type === RenewalOfferDecisionType.Declined) {
    input.decision = "Notice to vacate";
    // if there is no move out date, it means the resident soft declined
    // we will not show a move out date with the NTV decision
    if (value.decidedAt && !value.moveOutInfo.moveOutDate) {
      input.info = `Submitted on ${formatDate(new Date(value.decidedAt))}`;
    } else if (value.decidedAt && value.moveOutInfo.moveOutDate) {
      input.info = `Move out date ${formatDate(
        new Date(value.moveOutInfo.moveOutDate)
      )} - Submitted on ${formatDate(new Date(value.decidedAt))}`;
    }
  } else if (value?.unitUpgrade?.createdAt) {
    input.decision = "Upgrade request";
    input.info = `Unit ${
      value.unit?.building ? `${value.unit.building} - ` : ""
    }${value.unitUpgrade.unit?.marketingName} - Submitted on ${formatDate(
      new Date(value.unitUpgrade.createdAt)
    )}`;
  }

  return input;
};

const renderDecisionInfoCell = (
  params:
    | GridCellParams<DecisionRenewalOffer>
    | GridSortCellParams<DecisionRenewalOffer>
) => {
  const { value } = params as { value: DecisionRenewalOffer };
  const input = getDecisionInfo(value);
  return {
    sortValue: `${input.decision} ${input.info || ""}`,
    returnValue: (
      <div>
        <Body1 $size="xs" $weight="medium">
          {input.decision}
        </Body1>
        <Body1 $size="2xs" style={{ display: "flex", alignItems: "center" }}>
          {value?.externalDecision && (
            <ToolTip popperContent="This decision was made outside Renew. The displayed data is pulled from your PMS. To correct any errors, please update the information directly in the PMS.">
              <span
                style={{
                  fontWeight: 400,
                  color: "#475467",
                  marginRight: "3px",
                }}
              >
                <Styled.OfflineIcon />
                Decision made outside Renew{input.info && " - "}
              </span>
            </ToolTip>
          )}
          {input.info && input.info}
        </Body1>
      </div>
    ),
  };
};

const renderActionNeededCell = (
  params:
    | GridRenderCellParams<DeterminedStatusRenewalOffer>
    | GridSortCellParams<DeterminedStatusRenewalOffer>
) => {
  const { value } = params as { value: DeterminedStatusRenewalOffer };
  if (!value) {
    return {
      sortValue: "Renewal offers sent",
      returnValue: "Renewal offers sent",
    };
  }
  switch (true) {
    case (value.decisionType === RenewalOfferDecisionType.Interested ||
      value.decisionType === RenewalOfferDecisionType.InterestedLate) &&
      value.workflowStatus !== "COMPLETED":
      return {
        sortValue: "Awaiting Lease Signing",
        returnValue: (
          <ActionItemPill
            tooltipContent="Lease must be signed to complete renewal offer"
            copy="Awaiting Lease Signing"
          />
        ),
      };
    case value.workflowStatus === "COMPLETED": {
      const decisionMadeDate =
        value.decidedAt || value.signedOnDate ? (
          <Body1 $size="2xs">
            {value.signedOnDate
              ? `Lease signed on ${formatDate(new Date(value.signedOnDate))}`
              : value.decidedAt &&
                `Decision made ${formatDate(new Date(value.decidedAt))}`}
          </Body1>
        ) : null;

      const content =
        value.decisionType === RenewalOfferDecisionType.Declined
          ? "Notice to vacate complete!"
          : "Renewal complete!";

      return {
        sortValue: content,
        returnValue: (
          <div>
            <Body1 $size="xs" $weight="medium">
              {content}
            </Body1>
            {decisionMadeDate}
          </div>
        ),
      };
    }
    case Boolean(value.eSignature) &&
      value.decisionType === RenewalOfferDecisionType.Declined: {
      switch (value?.eSignature?.status) {
        // when all residents have signed but not partner, show action chip to sign
        case "ALL_RESIDENTS_SIGNED":
          return {
            sortValue: "Countersign: Notice to vacate",
            returnValue: (
              <ActionItemPill
                href={
                  value.unitId &&
                  `/units/${value.unitId}/countersign?source=renewal-offers`
                }
                tooltipContent="Countersign and finalize the submitted notice to vacate"
                copy="Countersign: Notice to vacate"
              />
            ),
          };
        // in any other case show awaiting resident NTV sign
        default:
          return {
            sortValue: "Awaiting resident NTV sign",
            returnValue: "Awaiting resident NTV sign",
          };
      }
    }
    case Boolean(value.mostRecentNeedSomethingElseRequest) &&
      !value.needSomethingElseResolvedAt:
      return {
        sortValue:
          value.mostRecentNeedSomethingElseRequest &&
          determineNeedSomethingElseCopy(
            value.mostRecentNeedSomethingElseRequest
          ),
        returnValue: (
          <StatusPill
            label={
              (value.mostRecentNeedSomethingElseRequest &&
                determineNeedSomethingElseCopy(
                  value.mostRecentNeedSomethingElseRequest
                )) ??
              ""
            }
            color="grey600"
            bgColor="arctic400"
            icon={<AttachEmailOutlined />}
          />
        ),
      };
    case Boolean(value.lastViewedAt):
      return {
        sortValue: `Awaiting resident decision Renewal offer viewed last on  ${formatDate(
          new Date(value.lastViewedAt || "")
        )}`,
        returnValue: (
          <div>
            <Body1 $size="xs" $weight="medium">
              Awaiting resident decision
            </Body1>
            <Body1 $size="2xs">
              Renewal offer viewed last on{" "}
              {value.lastViewedAt && formatDate(new Date(value.lastViewedAt))}
            </Body1>
          </div>
        ),
      };
    default:
      return {
        sortValue: "Renewal offers sent",
        returnValue: "Renewal offers sent",
      };
  }
};

const LastActionCell = ({ params }: { params: GridCellParams<RowType> }) => {
  const router = useRouter();

  const handleInteraction = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    const offerId = params.row?.id;
    if (offerId) {
      const currentPath = router.asPath;
      const [pathname, queryString] = currentPath.split("?");
      const query = new URLSearchParams(queryString || "");
      query.set("offerId", offerId);

      void router.push(`${pathname}?${query.toString()}`, undefined, {
        shallow: true,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Only trigger on Enter or Space key
    if (event.key === "Enter" || event.key === " ") {
      handleInteraction(event);
    }
  };

  return (
    <div
      onClick={handleInteraction}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-label="View unit details"
      style={{
        cursor: "pointer",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {params.row.renewalProfileInfo?.residentActions?.[0]?.name}{" "}
      {Styled.ArrowIcon && <Styled.ArrowIcon />}
    </div>
  );
};

const renewalProfileInfoColumn: GridColDef = {
  field: "renewalProfileInfo",
  headerName: `Last action in app`,
  cellClassName: "renewalOffer-theme--cell",
  headerClassName:
    "renewalOffer-theme--header renewalOffer-theme--header--last",
  headerAlign: "left",
  filterable: false,
  width: 230,
  align: "left",
  sortComparator: (_v1, _v2, param1, param2) =>
    (param1.value.residentActions?.[0]?.name ?? "").localeCompare(
      param2.value.residentActions?.[0]?.name ?? ""
    ),
  renderHeader: () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "24px",
        height: "30px",
      }}
    >
      <span
        style={{ display: "flex", alignItems: "center", minWidth: "170px" }}
      >
        Last action in app
        <ToolTip popperContent="Check out our new activity log to gain insights into your residents' behavior.">
          <span
            style={{
              borderRadius: "8px",
              background: `${addAlpha(
                defaultTheme.renewTheme.colors.informational400,
                0.1
              )}`,
              border: `1px solid ${defaultTheme.renewTheme.colors.informational400}`,
              color: defaultTheme.renewTheme.colors.black,
              padding: "4px 16px",
              marginLeft: "4px",
              fontSize: "12px",
            }}
          >
            New
          </span>
        </ToolTip>
      </span>
    </div>
  ),
  renderCell: (params: GridCellParams<RowType>) => (
    <LastActionCell params={params} />
  ),
};

const baseColumns: GridColDef[] = [
  {
    field: "unit",
    headerName: "Unit #",
    headerClassName:
      "renewalOffer-theme--header renewalOffer-theme--header__link renewalOffer-theme--header__first",
    cellClassName: "renewalOffer-theme--cell renewalOffer-theme--cell__link",
    headerAlign: "left",
    filterable: true,
    width: 215,
    sortComparator: (_v1, _v2, param1, param2) =>
      (param1.value.building
        ? `${param1.value.building as string} - ${param1.value.name as string}`
        : param1.value.name
      ).localeCompare(
        param2.value.building
          ? `${param2.value.building as string} - ${
              param2.value.name as string
            }`
          : param2.value.name
      ),
    align: "left",
    renderCell: (params: GridCellParams<RowType>) => (
      <div>
        <Styled.CellLink href={`/units/${params.row.unit?.id as string}`}>
          {params.row.unit?.building && `${params.row.unit?.building} - `}
          {params.row.unit?.name}
        </Styled.CellLink>
        <Body1 $size="2xs">{params.row.unit?.communityName}</Body1>
      </div>
    ),
  },
  {
    field: "determinedStatus",
    headerName: "Status",
    headerClassName:
      "renewalOffer-theme--header renewalOffer-theme--header__link",
    cellClassName: "renewalOffer-theme--cell renewalOffer-theme--cell__link",
    headerAlign: "left",
    filterable: true,
    width: 270,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (
        renderActionNeededCell(param1).sortValue?.toString() || ""
      ).localeCompare(
        renderActionNeededCell(param2).sortValue?.toString() || ""
      ),
    renderCell: (
      params:
        | GridRenderCellParams<DeterminedStatusRenewalOffer>
        | GridSortCellParams<DeterminedStatusRenewalOffer>
    ) => {
      const resolvedNSE = (
        <Styled.IconWrapper>
          {renderActionNeededCell(params).returnValue}{" "}
          <StatusPill
            color="grey600"
            bgColor="success25"
            icon={<MarkEmailReadOutlined />}
            tooltipText="Resident's request has been resolved"
          />
        </Styled.IconWrapper>
      );

      const hasNSE = (params.value?.mostRecentNeedSomethingElseRequest as
        | string
        | null) && (
        <Styled.IconWrapper>
          {renderActionNeededCell(params).returnValue}{" "}
          <StatusPill
            color="grey600"
            bgColor="arctic400"
            icon={<AttachEmailOutlined />}
            tooltipText={determineNeedSomethingElseCopy(
              params.value.mostRecentNeedSomethingElseRequest as string
            )}
          />
        </Styled.IconWrapper>
      );

      if (params.value?.needSomethingElseResolvedAt) {
        return resolvedNSE;
      }

      if (params.value?.mostRecentNeedSomethingElseRequest as string | null) {
        return hasNSE;
      }

      return renderActionNeededCell(params).returnValue;
    },
  },
  {
    field: "decision",
    headerName: "Decision",
    headerClassName: "renewalOffer-theme--header",
    cellClassName: "renewalOffer-theme--cell",
    headerAlign: "left",
    filterable: false,
    width: 355,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (
        renderDecisionInfoCell(param1).sortValue?.toString() || ""
      ).localeCompare(
        renderDecisionInfoCell(param2).sortValue?.toString() || ""
      ),
    renderCell: (params) => renderDecisionInfoCell(params).returnValue,
  },
  {
    field: "lease",
    headerName: "Notice deadline",
    cellClassName: "renewalOffer-theme--cell",
    headerClassName: "renewalOffer-theme--header",
    headerAlign: "left",
    filterable: false,
    width: 180,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (!param1.value.toDate
        ? "Month to month"
        : param1.value?.noticeDate?.toString() || ""
      ).localeCompare(
        !param2.value.toDate
          ? "Month to month"
          : param2.value?.noticeDate?.toString() || ""
      ),
    renderCell: (params: GridCellParams<RowType>) => {
      if (params.row.lease?.noticeDate) {
        return formatDate(params.row.lease.noticeDate);
      }
      if (!params.row.lease.toDate) {
        return "Month to month";
      }
      return "";
    },
  },
  {
    field: "leaseToDate",
    headerName: "Lease end date",
    cellClassName: "renewalOffer-theme--cell",
    headerClassName: "renewalOffer-theme--header",
    headerAlign: "left",
    filterable: false,
    width: 219,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (!param1.value
        ? "Month to month"
        : param1.value?.toDate?.toString() || ""
      ).localeCompare(
        !param2.value ? "Month to month" : param2.value?.toString() || ""
      ),
    renderCell: (params: GridCellParams<RowType>) => {
      if (params.row.leaseToDate) {
        return formatDate(params.row.leaseToDate);
      }
      return "Month to month";
    },
  },
];

// Conditionally add the renewalProfileInfo column based on feature flag
export const columns = ({
  residentActionsEnabled = false,
}: {
  residentActionsEnabled?: boolean;
}): GridColDef[] =>
  residentActionsEnabled
    ? [...baseColumns, renewalProfileInfoColumn]
    : baseColumns;
