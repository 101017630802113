import React from "react";
import { HelpOutline } from "@mui/icons-material";
import { Body1 } from "@/components/Typography";
import ToolTip from "@/components/Tooltip";
import { SplitColumnDataPanelProps } from "./types";
import * as Styled from "./styles";

const SplitColumnDataPanel: React.FC<SplitColumnDataPanelProps> = ({
  sections,
  emptyMessage = "No data available",
}) => {
  if (!sections || sections.length === 0) {
    return <Body1>{emptyMessage}</Body1>;
  }

  return (
    <Styled.Container>
      {sections.map((section, sectionIndex) => (
        <Styled.Section key={`section-${section.title ?? sectionIndex}`}>
          {section.title && (
            <Body1 $size="sm" $weight="medium">
              {section.title}
            </Body1>
          )}
          {section.items
            .filter((item) => item?.title)
            .map((item) => (
              <Styled.SectionItem key={item.title}>
                <Styled.DetailItem>
                  <ToolTip popperContent={item.tooltip || ""}>
                    <Styled.DataItemTitle $size="sm">
                      {item.title}
                      <HelpOutline />
                    </Styled.DataItemTitle>
                  </ToolTip>
                </Styled.DetailItem>
                <Body1 $size="sm" $weight="medium">
                  {item.value}
                </Body1>
              </Styled.SectionItem>
            ))}
        </Styled.Section>
      ))}
    </Styled.Container>
  );
};

export default SplitColumnDataPanel;
