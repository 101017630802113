import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";
import { Body1 } from "@/components/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SectionItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  :last-child {
    margin-bottom: 0.5rem;
  }
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DataItemTitle = styled(Body1)`
  color: ${defaultTheme.renewTheme.colors.grey600};
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    height: 12px;
    width: 12px;
  }
`;
